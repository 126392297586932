import React from "react";
import { Box, BaseBoxProps } from "../Box/Box";
import "./button-group.scss";

type Props = BaseBoxProps;

export const ButtonGroup: React.FC<Props> = React.forwardRef(({ children, ...props }, ref) => {
    return (
        <Box className="button-group" {...props} ref={ref}>
            {children}
        </Box>
    );
});
