import React from "react";

import { BaseBoxProps, Box } from "../Box/Box";
import { PseudoBox, PseudoBoxProps } from "../PseudoBox/PseudoBox";
import { Spinner } from "../Spinner/Spinner";

type ButtonIconProps = {
    icon: React.ComponentType;
} & BaseBoxProps;

export const ButtonIcon: React.FC<ButtonIconProps> = ({ icon, ...rest }) => {
    return <Box as={icon} {...rest} />;
};

export interface BaseBtnProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    width?: string | number;
    height?: string | number;
    margin?: string;
    htmlType?: string;
    active?: boolean;
}

const baseProps = {
    display: "inline-flex",
    appearance: "none",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    position: "relative",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    lineHeight: "1.2",
    outline: "none",
    border: "none",
    transition: "all 0.3s",
    _hover: {
        cursor: "pointer"
    }
};

const disabledProps = {
    _disabled: {
        cursor: "not-allowed",
        opacity: "45%"
    }
};

const buttonSizes = {
    "4xl": {
        fontSize: "4xl",
        paddingX: 16,
        height: 32,
        minWidth: 32
    },
    "3xl": {
        fontSize: "3xl",
        paddingX: 12,
        height: 24,
        minWidth: 24
    },
    "2xl": {
        fontSize: "2xl",
        paddingX: 10,
        height: 20,
        minWidth: 20
    },
    xl: {
        fontSize: "xl",
        paddingX: 8,
        height: 16,
        minWidth: 16
    },
    lg: {
        fontSize: "lg",
        paddingX: 6,
        height: 12,
        minWidth: 12
    },
    md: {
        fontSize: "md",
        paddingX: 4,
        height: 10,
        minWidth: 10
    },
    sm: { height: 8, minWidth: 8, fontSize: "sm", px: 3 },
    xs: {
        height: 6,
        minWidth: 6,
        fontSize: "xs",
        px: 2
    }
};

type Button = {
    size?: "4xl" | "3xl" | "2xl" | "xl" | "lg" | "md" | "sm" | "xs";
    isLoading?: boolean;
    isDisabled?: boolean;
    fullWidth?: boolean;
    variant?: "link" | "solid" | "outline" | "ghost";
    themeColor?: string;
    leftIcon?: React.ComponentType;
    rightIcon?: React.ComponentType;
    loadingText?: string;
};
export type ButtonProps = Button & PseudoBoxProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

//@ts-ignore
const getSize = ({ size = "md" }: ButtonProps) => buttonSizes[size];

const getSolidVariantStyle = ({ themeColor }: ButtonProps) => {
    let solidStyle = {
        bg: `${themeColor}.600`,
        color: "white",
        _hover: {
            bg: `${themeColor}.700`,
            cursor: "pointer"
        }
    };

    if (themeColor === "gray") {
        solidStyle = {
            bg: "gray.300",
            color: "gray.900",
            _hover: {
                bg: `${themeColor}.400`,
                cursor: "pointer"
            }
        };
    }
    return solidStyle;
};

const getOutlineVariantStyle = ({ themeColor }: ButtonProps) => {
    if (themeColor === "gray") {
        return {
            border: "1px",
            borderColor: `${themeColor}.300`,
            borderStyle: "solid",
            background: "transparent",
            color: "inherit",
            _hover: {
                bg: `${themeColor}.200`,
                cursor: "pointer"
            }
        };
    } else {
        return {
            border: "1px",
            borderColor: `${themeColor}.300`,
            borderStyle: "solid",
            background: "transparent",
            color: "inherit",
            _hover: {
                bg: `${themeColor}.100`,
                cursor: "pointer"
            }
        };
    }
};

const getLinkStyle = (props: ButtonProps) => {
    return {
        p: 0,
        height: "auto",
        bg: "transparent",
        color: `${props.themeColor}.600`,
        lineHeight: "normal",
        _hover: {
            textDecoration: "underline",
            cursor: "pointer"
        }
    };
};

const getGhostStyle = ({ themeColor }: ButtonProps) => {
    if (themeColor === "gray") {
        return {
            color: "inherit",
            background: "transparent",
            _hover: {
                bg: "gray.100",
                cursor: "pointer"
            },
            _active: {
                bg: "gray.200"
            }
        };
    } else {
        return {
            color: `${themeColor}.500`,
            background: "transparent",
            _hover: {
                bg: `${themeColor}.100`,
                cursor: "pointer"
            },
            _active: {
                bg: `${themeColor}.200`
            }
        };
    }
};

const getVariantStyle = (props: ButtonProps) => {
    if (props.variant === "solid") {
        return getSolidVariantStyle(props);
    } else if (props.variant === "link") {
        return getLinkStyle(props);
    } else if (props.variant === "outline") {
        return getOutlineVariantStyle(props);
    } else if (props.variant === "ghost") {
        return getGhostStyle(props);
    }
};

const useButtonStyle = (props: ButtonProps) => {
    return {
        ...baseProps,
        ...getSize(props),
        //@ts-ignore
        ...getVariantStyle(props),
        ...disabledProps
    };
};

export const NewButton: React.FC<ButtonProps> = React.forwardRef(
    (
        {
            children,
            size = "md",
            variant = "solid",
            themeColor = "gray",
            leftIcon,
            rightIcon,
            isLoading,
            loadingText,
            ...props
        },
        ref
    ) => {
        const styles = useButtonStyle({
            //@ts-ignore
            size,
            themeColor,
            //@ts-ignore
            variant
        });

        // has to append <span> to string otherwise google translate will cause crash
        const isString = typeof children === "string";
        return (
            <PseudoBox
                ref={ref}
                as="button"
                type="button"
                width={props.fullWidth ? "100%" : undefined}
                borderRadius="md"
                fontWeight="bold"
                disabled={props.isDisabled || isLoading}
                {...styles}
                {...props}
            >
                {leftIcon && !isLoading && <ButtonIcon ml={-1} mr={2} icon={leftIcon} />}
                {isLoading ? (
                    <>
                        <Spinner
                            position={loadingText ? "relative" : "absolute"}
                            size="small"
                            margin={loadingText ? "0 0.5rem 0 0" : "0px"}
                            color="currentColor"
                        />
                        {loadingText && <Box as="span">{loadingText}</Box>}
                    </>
                ) : isString ? (
                    <span>{children}</span>
                ) : (
                    children
                )}
                {rightIcon && !isLoading && <ButtonIcon mr={-1} ml={2} icon={rightIcon} />}
            </PseudoBox>
        );
    }
);

export const Button = NewButton;
