import Axios from "axios";

import { envConstants } from "Constants";
import { OrderWindow } from "Types";

export const fetchActiveOrderWindowById = async (orderWindowId: string) => {
    if (!orderWindowId) {
        console.trace(`Cannot fetch orderWindowId=${orderWindowId}`);
        return;
    }
    return await Axios.get<{ orderWindow: OrderWindow }>(
        `${envConstants.QSOCKET_SERVICE_URL}/api/${orderWindowId}/active`,
        {
            headers: { "Cache-control": "no-cache" }
        }
    );
};

export const fetchOrderWindowById = async (orderWindowId: string) => {
    if (!orderWindowId) {
        console.trace(`Cannot fetch orderWindowId=${orderWindowId}`);
        return;
    }
    return await Axios.get<{ orderWindow: OrderWindow }>(`${envConstants.QSOCKET_SERVICE_URL}/api/${orderWindowId}`, {
        headers: { "Cache-control": "no-cache" }
    });
};
