import styled from "styled-components";
import {
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    compose,
    borderRadius,
    system,
    SpaceProps,
    ColorProps,
    TypographyProps,
    LayoutProps,
    FlexboxProps,
    GridProps,
    BackgroundProps,
    BorderProps,
    PositionProps,
    ShadowProps,
    BorderRadiusProps,
    ResponsiveValue,
    Config,
    HeightProps,
    MinHeightProps,
    MaxHeightProps,
    WidthProps,
    MinWidthProps,
    MaxWidthProps
} from "styled-system";
import { createShouldForwardProp, props } from "@styled-system/should-forward-prop";

type As = {
    as?: React.ElementType;
};

type CSS = React.CSSProperties;

type BorderRadius = BorderRadiusProps["borderRadius"];

interface CustomConfig {
    rounded?: BorderRadius;
    roundedTop?: BorderRadius;
    roundedBottom?: BorderRadius;
    roundedLeft?: BorderRadius;
    roundedRight?: BorderRadius;
    roundedTopRight?: BorderRadius;
    roundedTopLeft?: BorderRadius;
    roundedBottomRight?: BorderRadius;
    roundedBottomLeft?: BorderRadius;
    objectFit: ResponsiveValue<CSS["objectFit"]>;
    cursor: ResponsiveValue<CSS["cursor"]>;
    whiteSpace: ResponsiveValue<CSS["whiteSpace"]>;
    transition: ResponsiveValue<CSS["transition"]>;
    opacity: ResponsiveValue<CSS["opacity"]>;
    textDecoration: ResponsiveValue<CSS["textDecoration"]>;
    filter: ResponsiveValue<CSS["filter"]>;
    transform: ResponsiveValue<CSS["transform"]>;
    userSelect: ResponsiveValue<CSS["userSelect"]>;
    listStyle: ResponsiveValue<CSS["listStyle"]>;
    listStyleType: ResponsiveValue<CSS["listStyleType"]>;
    resize: ResponsiveValue<CSS["resize"]>;
    wordBreak: ResponsiveValue<CSS["wordBreak"]>;
    wordWrap: ResponsiveValue<CSS["wordWrap"]>;
    textTransform: ResponsiveValue<CSS["textTransform"]>;
    borderCollapse: ResponsiveValue<CSS["borderCollapse"]>;
    boxShadow: ResponsiveValue<CSS["boxShadow"]>;
    clip?: ResponsiveValue<CSS["clip"]>;
    boxSizing?: ResponsiveValue<CSS["boxSizing"]>;
    content?: ResponsiveValue<CSS["content"]>;
    textOverflow?: ResponsiveValue<CSS["textOverflow"]>;
    pointerEvents?: ResponsiveValue<CSS["pointerEvents"]>;
    appearance?: ResponsiveValue<CSS["appearance"]>;
    outline?: ResponsiveValue<CSS["outline"]>;
    outlineColor?: ResponsiveValue<CSS["outlineColor"]>;
    outlineOffset?: ResponsiveValue<CSS["outlineOffset"]>;
    outlineStyle?: ResponsiveValue<CSS["outlineStyle"]>;
    outlineWidth?: ResponsiveValue<CSS["outlineWidth"]>;
    zoom?: ResponsiveValue<CSS["zoom"]>;
    rotate?: ResponsiveValue<CSS["rotate"]>;

    // Custom height alias
    h?: HeightProps["height"];
    minH?: MinHeightProps["minHeight"];
    maxH?: MaxHeightProps["maxHeight"];
    // Custom width alias
    w?: WidthProps["width"];
    minW?: MinWidthProps["minWidth"];
    maxW?: MaxWidthProps["maxWidth"];
}

type StyledSystemTypes = SpaceProps &
    ColorProps &
    TypographyProps &
    LayoutProps &
    FlexboxProps &
    GridProps &
    BackgroundProps &
    BorderProps &
    PositionProps &
    ShadowProps;

type HTMLProps = React.RefAttributes<any> & React.HTMLAttributes<any>;

export type BaseBoxProps = StyledSystemTypes & As & Partial<CustomConfig> & HTMLProps;

// custom alias config
export const config = {
    roundedTop: {
        properties: ["borderTopLeftRadius", "borderTopRightRadius"],
        scale: "radii"
    },
    roundedBottom: {
        properties: ["borderBottomLeftRadius", "borderBottomRightRadius"],
        scale: "radii"
    },
    roundedLeft: {
        properties: ["borderTopLeftRadius", "borderBottomLeftRadius"],
        scale: "radii"
    },
    roundedRight: {
        properties: ["borderTopRightRadius", "borderBottomRightRadius"],
        scale: "radii"
    },
    roundedTopRight: {
        property: "borderTopRightRadius",
        scale: "radii"
    },
    roundedTopLeft: {
        property: "borderTopLeftRadius",
        scale: "radii"
    },
    roundedBottomRight: {
        property: "borderBottomRightRadius",
        scale: "radii"
    },
    roundedBottomLeft: {
        property: "borderBottomLeftRadius",
        scale: "radii"
    },
    borderRadius: {
        property: "borderRadius",
        scale: "radii"
    },
    boxShadow: {
        property: "boxShadow",
        scale: "shadows"
    },
    h: {
        property: "height",
        scale: "sizes"
    },
    minH: {
        property: "minHeight",
        scale: "sizes"
    },
    maxH: {
        property: "maxHeight",
        scale: "sizes"
    },
    w: {
        property: "width",
        scale: "sizes"
    },
    minW: {
        property: "minWidth",
        scale: "sizes"
    },
    maxW: {
        property: "maxWidth",
        scale: "sizes"
    },
    opacity: true,
    textDecoration: true,
    objectFit: true,
    whiteSpace: true,
    transition: true,
    transitionDelay: true,
    animation: true,
    willChange: true,
    filter: true,
    cursor: true,
    transform: true,
    transformOrigin: true,
    translateX: true,
    translateY: true,
    userSelect: true,
    listStyle: true,
    listStyleType: true,
    resize: true,
    wordBreak: true,
    wordWrap: true,
    textTransform: true,
    borderCollapse: true,
    content: true,
    textOverflow: true,
    pointerEvents: true,
    appearance: true,
    clip: true,
    boxSizing: true,
    outline: true,
    zoom: true,
    rotate: true,
    outlineColor: true,
    outlineOffset: true,
    outlineStyle: true,
    outlineWidth: true
} as Config;

config.rounded = config.borderRadius;

export const specialConfig = system(config);
export const systemProps = compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    borderRadius,
    specialConfig
);

export const shouldForwardProp = createShouldForwardProp(props);

export const Box = styled("div").withConfig({
    shouldForwardProp: prop => shouldForwardProp(prop)
})<BaseBoxProps>`
    ${systemProps}
`;
