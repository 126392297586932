import React from "react";
import styled, { keyframes } from "styled-components";

import { BaseBoxProps, Box } from "Atoms";

const opacity = keyframes`
    0% { will-change: opacity; opacity: 0};
    100% { will-change: opacity; opacity: 1};
`;

const BoxAnimation = styled(Box)`
    animation: 400ms ${opacity} ease-in;
`;

/** Used in many places, wanted a simple animation for smoothing out the transitions */
export const AnimationBox: React.FC<BaseBoxProps> = ({ ...props }) => <BoxAnimation {...props} />;
