import React, { useEffect } from "react";

import { useMothershipLazyQuery, useTypedQuery, useCookieConsent } from "Hooks";
import { FindUserByRefreshTokenQueryQuery, FindUserByRefreshTokenQueryQueryVariables } from "../generated/graphql";
import { GET_USER_ACCOUNT_SUBSCRIPTIONS, FIND_USER_ACCOUNT_BY_HASHED_REFRESH_TOKEN } from "GraphQLQueries";
import { CookieKeys, UserSubscriptionDTO } from "Types";

export const UserInfo = () => {
    const { selectedCookie: jwtRefreshToken } = useCookieConsent(CookieKeys.JWT_REFRESH_TOKEN);

    const { data: userAccount, loading } = useTypedQuery<
        FindUserByRefreshTokenQueryQuery,
        FindUserByRefreshTokenQueryQueryVariables
    >(FIND_USER_ACCOUNT_BY_HASHED_REFRESH_TOKEN, {
        variables: {
            refreshToken: jwtRefreshToken
        },
        skip: !jwtRefreshToken
    });

    const [getUserAccountSubs, { data: subscriptionsData, loading: userSubsLoading }] =
        useMothershipLazyQuery(GET_USER_ACCOUNT_SUBSCRIPTIONS);

    useEffect(() => {
        if (userAccount?.findUserByRefreshToken?.id) {
            getUserAccountSubs({
                variables: {
                    userAccountId: userAccount?.findUserByRefreshToken.id
                }
            });
        } else if (!loading) {
            window.ReactNativeWebView && window.ReactNativeWebView.postMessage("no_user_data");
        }
    }, [userAccount]);

    useEffect(() => {
        if (subscriptionsData && !userSubsLoading) {
            const { contactInformation } = userAccount?.findUserByRefreshToken!;
            const externalSubscriptionData = subscriptionsData.getUserSubscriptions.map(
                (subscription: UserSubscriptionDTO) => {
                    const {
                        creditCardInfo,
                        recurringPaymentOrders,
                        subscriptionRedemptionOrders,
                        __typename,
                        ...rest
                    } = subscription;
                    return rest;
                }
            );

            const userData = {
                id: userAccount?.findUserByRefreshToken?.id,
                name: contactInformation.name,
                lastName: contactInformation.lastName,
                email: contactInformation.email,
                phone: contactInformation.phoneNumber,
                subscriptions: externalSubscriptionData
            };
            setTimeout(() => {
                window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify({ userData }));
            }, 2000);
        }
    }, [subscriptionsData]);

    return <></>;
};
