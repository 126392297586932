import React from "react";
import { RiInformationLine } from "@react-icons/all-files/ri/RiInformationLine";

import { ToolTip, BaseBoxProps, Box, Flex } from "Atoms";
import { useLanguage } from "Providers/languageProvider/LanguageProvider";

const getThemeStyles = (themeColor: string) => {
    if (themeColor === "gray") {
        return {
            bg: "gray.300",
            color: "gray.900"
        };
    } else {
        return {
            bg: `${themeColor}.400`,
            color: "white"
        };
    }
};

const labelStyles = {
    fontSize: "0.9rem",
    cursor: "help",
    h: "auto",
    rounded: 8,
    alignItems: "center"
};

type BetaLabelProps = {
    labelBeforeText?: boolean;
    themeColor?: string;
    shouldShowBetaExplanation?: boolean;
} & BaseBoxProps;

export const BetaLabel: React.FC<BetaLabelProps> = ({
    children,
    labelBeforeText = true,
    themeColor = "gray",
    shouldShowBetaExplanation = true,
    ...rest
}) => {
    const { translate } = useLanguage();
    const themeStyles = getThemeStyles(themeColor);

    const childrenMargin = labelBeforeText ? { marginLeft: 2 } : { marginRight: 2 };
    const wrappedChildren = (
        <Box mt={4} mb={0} {...childrenMargin}>
            {children}
        </Box>
    );

    return (
        <Flex alignItems={"center"} {...rest}>
            {labelBeforeText ? <></> : wrappedChildren}
            <ToolTip
                placement={"bottom"}
                enabled={shouldShowBetaExplanation}
                text={translate("betaExplanation")}
                w="auto"
            >
                <Flex py={1} px={2} {...labelStyles} {...themeStyles}>
                    <Box as="span">BETA</Box>
                    <Box as={RiInformationLine} rounded="full" height="100%" ml={1} />
                </Flex>
            </ToolTip>
            {labelBeforeText ? wrappedChildren : <></>}
        </Flex>
    );
};
